import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import IconButton from '@material-ui/core/IconButton';
import { FaDownload } from 'react-icons/fa';

import styles from './resource-preview.module.css'

export default ({ resource }) => (
  <div className={styles.preview}>
    <Img className={styles.heroImage} alt="" fluid={resource.heroImage.fluid} />
    <h3 className={styles.previewTitle}>
      <Link to={`/resource/${resource.slug}`}>{resource.title}</Link>
    </h3>
    <small>{resource.publishDate}</small>
    <div
      dangerouslySetInnerHTML={{
        __html: resource.description.childMarkdownRemark.html,
      }}
    />
   <IconButton aria-label="Download" href={resource.link} target="_blank" disabled={resource.link == null}>      
      <FaDownload />
    </IconButton>      
  </div>
)
