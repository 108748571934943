import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './Resources.module.css'
import Layout from '../components/layout'
import HeroLayout from '../components/heroLayout'
import ResourcePreview from '../components/resource-preview'

class Resources extends React.Component {
    render() {
      const siteTitle = get(this, 'props.data.site.siteMetadata.title')
      const resourcesPost = get(this, 'props.data.allContentfulResource.edges')
      const [layoutHeroImage] = get(this, 'props.data.allContentfulLayoutHeroImage.edges')      
  
      return (
        <Layout location={this.props.location}>
          <div style={{ background: '#fff' }}>
            <Helmet title={siteTitle} />
            <HeroLayout data={layoutHeroImage.node} />
            <div className="wrapper">
              <h2 className="section-headline">Resources</h2>
              <ul className="article-list">
                {resourcesPost.map(({ node }) => {
                  return (
                    <li key={node.slug}>
                      <ResourcePreview resource={node} />
                    </li>
                  )
                })}
              </ul>              
            </div>
          </div>
        </Layout>
      )
    }
  }
  
  export default Resources

  export const resourcesQuery = graphql`
  query ResourcesQuery {
    allContentfulLayoutHeroImage(filter: {title: {regex: "/Resources 2/"}}, limit: 1) {
      edges {
        node {
          title
          headline
          backgroundImage {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }          
          }
        }
      }
    }
    allContentfulResource(sort: {fields: [publishDate], order: DESC}) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid
              }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }  
`